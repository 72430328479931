@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {}

@import "@fontsource/abhaya-libre";










